<template>
  <div class="">
    <div
      class="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col bg-white"
    >
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div
        class="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6"
      >
        <div class="pt-4 pb-4">
          <ProfileUser
            class="flex-grow"
            :profile="profile"
            :is-quo-pro="isQuoPro"
            :show-quo-pro-badge="false"
          />
        </div>
        <nav class="flex flex-1 flex-col">
          <ul role="list" class="flex flex-1 flex-col gap-y-7">
            <li>
              <ul role="list" class="-mx-2 space-y-1">
                <li v-for="item in navigation" :key="item.name">
                  <NuxtLink
                    :to="localePath({ name: item.route })"
                    :class="[
                      isCurrentRoute(item.route)
                        ? 'bg-gray-50 text-indigo-600'
                        : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                      'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                    ]"
                  >
                    <SvgIcon
                      :name="item.icon"
                      style="solid"
                      :class="[
                        isCurrentRoute(item.route)
                          ? 'text-indigo-600'
                          : 'text-gray-400 group-hover:text-indigo-600',
                        'h-6 w-6 shrink-0',
                      ]"
                      aria-hidden="true"
                    />
                    {{ item.name }}
                  </NuxtLink>
                </li>
              </ul>
            </li>
            <li>
              <div class="text-xs font-semibold leading-6 text-gray-400">
                Helper tables
              </div>
              <ul role="list" class="-mx-2 mt-2 space-y-1">
                <li v-for="table in helper" :key="table.name">
                  <NuxtLink
                    :to="localePath({ name: table.route })"
                    :class="[
                      isCurrentRoute(table.route)
                        ? 'bg-gray-50 text-indigo-600'
                        : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                      'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                    ]"
                  >
                    <span
                      :class="[
                        isCurrentRoute(table.route)
                          ? 'text-indigo-600 border-indigo-600'
                          : 'text-gray-400 border-gray-200 group-hover:border-indigo-600 group-hover:text-indigo-600',
                        'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white',
                      ]"
                      >{{ table.initial }}</span
                    >
                    <span class="truncate">{{ table.name }}</span>
                  </NuxtLink>
                </li>
              </ul>
            </li>
            <li class="mt-auto">
              <div class="sm:flex items-center hidden mb-4">
                <div>
                  <img
                    src="/logo/quo-logo-512@2x.png"
                    alt=""
                    class="w-12 h-12 rounded-lg mr-3"
                  />
                </div>
                <div class="font-bold text-[22px] text-panel-quo-text">
                  Quo Dashboard
                </div>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <slot></slot>
    <OpenaiChat />
  </div>
</template>
<script setup>
const profileStore = useProfileStore()
const { isQuoPro, profile } = storeToRefs(profileStore)

const iconStore = useIconStore()
const tagStore = useTagStore()
const dashboardItemStore = useDashboardItemStore()
const geoEntityStore = useGeoEntityStore()

const navigation = [
  { name: 'Dashboard', route: 'dashboard', icon: 'home', current: true },
  {
    name: 'Places',
    route: 'dashboard-places-id',
    icon: 'globe-alt',
    current: false,
  },
  {
    name: 'Feed',
    route: 'dashboard-feed-id',
    icon: 'sparkles',
    current: false,
  },
  {
    name: 'Media',
    route: 'dashboard-media-id',
    icon: 'photo',
    current: false,
  },
  {
    name: 'Users',
    route: 'dashboard-users',
    icon: 'user',
    current: false,
  },
]

const helper = [
  {
    id: 1,
    name: 'Countries, States, Cities',
    route: 'dashboard-helper-countries',
    initial: 'c',
    current: false,
  },
  {
    id: 2,
    name: 'Tags',
    route: 'dashboard-helper-tags-id',
    initial: 't',
    current: false,
  },
  {
    id: 3,
    name: 'Icons',
    route: 'dashboard-helper-icons',
    initial: 'i',
    current: false,
  },
]
const isCurrentRoute = (route) => {
  const routeBaseName = useNuxtApp().$getRouteBaseName()
  return routeBaseName === route
}

await useAsyncData(
  'icons-tags-dashboard-detail-keys-dashboard-geo-entities-geo-entities',
  async () => {
    const [
      icons,
      tags,
      dashboardDetailKeys,
      dashboardGeoEntities,
      geoEntities,
    ] = await Promise.all([
      iconStore.fetchIcons(),
      tagStore.fetchTags(),
      dashboardItemStore.fetchDetailKeys(),
      dashboardItemStore.fetchGeoEntities(),
      geoEntityStore.fetchGeoEntities(),
    ])
    return {
      icons,
      tags,
      detailKeys,
      dashboardDetailKeys,
      dashboardGeoEntities,
      geoEntities,
    }
  }
)
</script>
