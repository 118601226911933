<template>
  <TransitionRoot as="template" :show="openaiChatPanelOpen">
    <Dialog as="div" class="relative z-10" @close="openaiChatPanelOpen = false">
      <div class="fixed inset-0" />

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div
            class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10"
          >
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
            >
              <DialogPanel class="pointer-events-auto w-screen max-w-md">
                <div
                  class="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
                >
                  <div
                    class="flex min-h-0 flex-1 flex-col overflow-y-scroll py-6"
                  >
                    <div class="px-4 sm:px-6">
                      <div class="flex items-start justify-between">
                        <DialogTitle
                          class="flex items-center text-base font-semibold leading-6 text-gray-900"
                          ><SvgIcon
                            name="openai"
                            type="solid"
                            class="w-5 h-5 fill-gray-900 mr-1"
                          />
                          ChatGPT</DialogTitle
                        >
                        <div class="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            class="relative rounded-md bg-white text-gray-400 hover:text-gray-500 outline-none"
                            @click="openaiChatPanelOpen = false"
                          >
                            <span class="absolute -inset-2.5" />
                            <span class="sr-only">Close panel</span>
                            <SvgIcon
                              name="x-mark"
                              type="solid"
                              class="h-6 w-6"
                              aria-hidden="true"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div
                      class="relative mt-6 flex-1 px-4 sm:px-6 text-sm divide-y divide-gray-200 text-gray-800"
                    >
                      <!-- Your content -->
                      <div
                        v-if="chatMessages.length === 0"
                        class="flex h-full items-center justify-center"
                      >
                        <div
                          class="grid gap-4 grid-cols-2 border border-gray-200 rounded-md divide-x divide-gray-200"
                        >
                          <div
                            v-for="(role, roleIndex) in roles"
                            :key="roleIndex"
                            class="p-4 cursor-pointer"
                            @click="setRole(role)"
                          >
                            <div>{{ role.name }}</div>
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <div class="flex pb-4">
                          <div
                            class="py-1.5 px-2 bg-gray-200 rounded cursor-pointer"
                            @click="clearChat()"
                          >
                            Clear chat
                          </div>
                        </div>
                        <div
                          v-for="(message, index) in chatMessages"
                          :key="index"
                          class="flex"
                        >
                          <div class="p-4 w-24 flex-shrink-0 text-xs leading-5">
                            <span class="uppercase font-semibold">{{
                              message.role
                            }}</span>
                          </div>
                          <div class="p-4 whitespace-pre-line leading-5">
                            {{ message.content }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-shrink-0 justify-end px-4 py-4 text-sm">
                    <textarea
                      ref="textarea"
                      v-model="input"
                      class="resize-none border border-gray-200 rounded-md w-full py-1.5 px-2"
                    />

                    <button
                      type="submit"
                      class="ml-4 inline-flex justify-center rounded-md bg-brand-openai px-3 py-2 text-sm font-semibold text-white shadow-sm"
                      @click="sendMessages()"
                    >
                      <SvgIcon
                        name="paper-airplane"
                        type="outline"
                        class="w-5 h-5 outline-white"
                      />
                    </button>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
<script setup>
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import { get, useTextareaAutosize } from '@vueuse/core'

const { textarea, input } = useTextareaAutosize()
const openaiStore = useOpenaiStore()
const { contentId, inputValue, openaiChatPanelOpen, roles } =
  storeToRefs(openaiStore)

const chatMessages = ref([])
const responsePending = ref(false)

const getMessages = () => {
  if (!contentId.value) {
    return
  }
  if (chatMessages.value.length === 0) {
    return
  }
  const messages = openaiStore.getMessages(contentId.value)
  if (messages.length > 0) {
    chatMessages.value = messages
  } else {
    chatMessages.value = []
  }
}

const sendMessages = async () => {
  if (input.value && input.value.length > 0) {
    chatMessages.value.push({
      role: 'user',
      content: input.value,
    })
    input.value = ''
  }
  const headers = useRequestHeaders(['cookie'])
  const { data, error } = await useFetch('/api/openai/chat/', {
    method: 'POST',
    body: chatMessages.value,
    headers,
  })
  if (error.value) {
    console.error('sendMessages: ', error.value)
    return
  }
  if (data.value && data.value.data && data.value.data.choices.length > 0) {
    const response = data.value.data.choices[0].message
    chatMessages.value.push({
      role: response.role,
      content: response.content,
    })
    setMessages(contentId.value, chatMessages.value)
  }
}

const setMessages = (id, messages) => {
  if (!id) {
    return
  }
  openaiStore.setMessages(id, messages)
}

const setRole = (role) => {
  chatMessages.value.push({
    role: 'system',
    content: role.system,
  })
  if (role.id === 1) {
    chatMessages.value.push({
      role: 'user',
      content: role.user + inputValue.value,
    })
  }
}

const clearChat = () => {
  chatMessages.value = []
  openaiStore.setMessages(contentId.value, [])
}

watch(
  () => openaiChatPanelOpen.value,
  (open) => {
    console.log('openaiChatPanelOpen', open)
    if (open) {
      getMessages()
    }
  }
)
</script>
